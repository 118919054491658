// Docs.js
import React, { useState, useRef } from 'react';
import '../style/docs.css';

function Docs({ title, desc, id}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="docs-row">
            <div className="Document_Name">
                <h4 id={id}>{title}</h4>
                <p>{desc}</p>
            </div>
            <div className="Uploaded-file-name">
                <input
                    type="file"
                    id={`${id}_filename`} 
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <p>{fileName ? fileName : "No file selected"}</p>
                <button type="button" onClick={triggerFileInput}>
                    {fileName ? 'Change File' : 'Choose File'}
                </button>
            </div>
        </div>
    );
}

export default Docs;