import React, { useState } from 'react';
import '../style/home.css';
import { useNavigate } from 'react-router-dom';
// Define the array of loan types
const loanTypes = [
  { id: 'first-time-buyer', label: 'First Time Buyer Mortgage' },
  { id: 'buy-to-let', label: 'Buy to Let Mortgage' },
  { id: 'remortgage', label: 'Remortgage' },
];

function Home() {
  const [loanType, setLoanType] = useState('');
  const [applicantCount, setApplicantCount] = useState(1);
  const navigate = useNavigate();

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const handleProceed = () => {
    if (loanType && applicantCount > 0) {
      navigate('/personal-details', { state: { loanType, applicantCount } });
    } else {
      alert('Please select loan type and enter number of applicants');
    }
  };

  return (
    <div className="home_container">
      <main className="main-content">
        <h1>Mortgage Loan Application Form</h1>
        <h2>
          Select Loan Type
          <select value={loanType} onChange={handleLoanTypeChange} style={{ margin: '0px 10px' }}>
            <option value="">Select a loan type</option>
            {loanTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.label}
              </option>
            ))}
          </select>
        </h2>
        <h2>
          Number of Applicants
          <input
            type="number"
            className="applicant-count"
            placeholder="Enter No. of Applicants"
            value={applicantCount}
            onChange={(e) => setApplicantCount(e.target.value)}
            min="1"
          />
        </h2>
        <button type="button" className="proceed-btn" onClick={handleProceed}> Proceed </button>
        {/* <p style={{ display: 'none' }}>
          Click Here To Upload Documents
          <Link to={`/Docs`}>
            <button type="button" className="upload-btn">Upload</button>
          </Link>
        </p> */}
      </main>
    </div>
  );
}

export default Home;