import React, { useState } from "react";
import '../style/personaldetailform.css';
import { ButtonComponent, DateOfBirthPicker, InputField, Radiobutton, SelectBox } from "../component/Common";

function PersonalDetails() {
    const [maritalStatus, setMaritalStatus] = useState('');
    const [voterRollStatus, setVoterRollStatus] = useState('');
    const [dependantsCount, setdependantsCount] = useState(null);

    //marital status array
    const maritalStatusOptions = [
        { label: 'Married', value: 'Married' },
        { label: 'Unmarried', value: 'Unmarried' },
        { label: 'Divorced', value: 'Divorced' },
        { label: 'Separated', value: 'Separated' },
    ];

    //voting role array
    const votersRoll = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const SelectDependants = [
        { label: 'Select No. of Dependants', value: null },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
    ]

    // Handle marital status change
    const handleMaritalStatusChange = (selectedValue) => {
        setMaritalStatus(selectedValue);
        console.log('Selected Marital Status:', selectedValue);
    };

    // Handle voter roll status change
    const handleVoterRollChange = (selectedValue) => {
        setVoterRollStatus(selectedValue);
        console.log('Selected Voter Roll Status:', selectedValue);
    };

    const handleDependantCount = (selectedValue) => {
        console.log('handleDependantCount:', selectedValue);
        setdependantsCount(parseInt(selectedValue, 10));
    }
    return (
        <>
            <div className="main_container">
                <div>
                    <h1 className="form_name">Personal Details</h1>
                </div>
                <div className="personal-details-form">
                    <InputField label="Title:" htmlFor="title" placeholder="Enter your name" />
                    <InputField label="Forename(s):" htmlFor="forename" placeholder="Enter your Forename(s)" />
                    <InputField label="Surname:" htmlFor="surname" placeholder="Enter your surname" />
                    <InputField label="Previous name(s) and dates of change:" htmlFor="previousName" placeholder="Enter previous name(s)" />
                    <DateOfBirthPicker />
                    <InputField label="Mother's Maiden Name:" htmlFor="motherMaidenName" placeholder="Enter mother's maiden name" />
                    <InputField label="Home Telephone No:" htmlFor="homePhone" placeholder="Enter home telephone number" />
                    <InputField label="Mobile Telephone No:" htmlFor="mobilePhone" placeholder="Enter mobile number" />
                    <InputField label="Work Telephone No:" htmlFor="workPhone" placeholder="Enter work telephone number" />
                    <InputField label="Email Address:" htmlFor="email" placeholder="Enter email address" />
                    <Radiobutton
                        label="Marital Status"
                        options={maritalStatusOptions}
                        name="marital-status"
                        value={maritalStatus}
                        onChange={handleMaritalStatusChange}
                    />

                    <SelectBox
                        label="Select Number of Dependants :"
                        options={SelectDependants}
                        value={dependantsCount}
                        name="Dependants-count"
                        onChange={handleDependantCount}
                    />
                    {dependantsCount == null ? <div /> : ([...Array(dependantsCount)].map((_, i) =>
                        <InputField key={i} label={`dependant ${i + 1}`} htmlFor="dependants" placeholder="Enter number of dependants" />
                    ))}
                    <InputField label="No. of dependants:" htmlFor="dependants" placeholder="Enter number of dependants" />
                    <InputField label="Nationality:" htmlFor="nationality" placeholder="Enter your nationality" />
                    <InputField label="Residency status in UK:" htmlFor="residency" placeholder="Enter residency status" />
                    <InputField label="Time in UK & Visa Type:" htmlFor="visa" placeholder="Enter visa type" />
                    <InputField label="Smoker & How Many:" htmlFor="smoker" placeholder="Enter smoking details" />
                    <InputField label="Current address (3 years address history required):" htmlFor="currentAddress" placeholder="Enter current address" />
                    <Radiobutton
                        label="Currently on voters roll?"
                        options={votersRoll}
                        name="voting-eligibility"
                        value={voterRollStatus}
                        onChange={handleVoterRollChange}
                    />
                    <InputField label="Length of time at address:" htmlFor="lengthAtAddress" placeholder="Enter length of time at address" />
                    <InputField label="Status at current address:" htmlFor="statusAtAddress" placeholder="Enter status" />
                    <InputField label="Previous address:" htmlFor="previousAddress" placeholder="Enter previous address" />
                    <InputField label="Length of time at previous address:" htmlFor="prevLengthAtAddress" placeholder="Enter length of time" />
                    <InputField label="Status at previous address:" htmlFor="statusAtPreviousAddress" placeholder="Enter status" />
                    <ButtonComponent name="Proceed" />
                </div>
            </div>
        </>
    );
}

export default PersonalDetails;