import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './pages/home';
import UploadDocument from './pages/UploadDocuments';
import PersonalDetails from './pages/personal-details';


const Layout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

const routers = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "docs",
        element: <UploadDocument />,
      }, 
      {
        path: "personal-details",
        element: <PersonalDetails />,
      },
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={routers} />
  </React.StrictMode>
);

reportWebVitals();
