import './App.css';
import Home from './pages/home';

function App() {
  return (
    <div className="App">     
      <div className="content">
        <Home />
      </div>    
    </div>
  );
}

export default App;
