import React from 'react';
import '../style/docs.css';
import Docs from '../component/Docs';


function UploadDocument() {

    var documentsList = [
        {
            title: "UK PASSPORT ID",
            desc: "(If not a British Passport – then all Visa Pages are Required)",
        },
        {
            title: "DRIVING LICENCE ID",
            desc: ''
        },
        {
            title: 'UTILITY BILL',
            desc: 'bills only dated within last 3 months OR Annual Council Tax Bill'
        },
        {
            title: 'EMPLOYED',
            desc: '3 Months LATEST payslips & Latest P60'
        },
        {
            title: 'SELF EMPLOYED',
            desc: "3 Years LATEST SA302's AND 3 Years Tax Overviews"
        },
        {
            title: 'BANK STATEMENTS',
            desc: '3 Months LATEST Bank Statements showing were SALARY is credited & House Bills are paid from'
        },
        {
            title: 'BENEFITS AWARD LETTER',
            desc: 'showing benefits credited (Working Tax Credit, Child Tax Credit, Child Benefit, Carers Allowance, Disability Living Allowance and Employment Support Allowance etc)'
        },
        {
            title: 'MORTGAGE STATEMENT',
            desc: 'Show ALL Existing Mortgages'
        },
        {
            title: 'PROOF OF DEPOSIT',
            desc: 'If Proof of Deposit Bank Statement has Transfers of £1,000+ then evidence of amount is required'
        },
        {
            title: 'CREDIT REPORTS',
            desc: ''
        },
        {
            title: 'BROKER FEE AGREED',
            desc: '(details on fee agreement) signed by client(s)'
        },
    ]

    return (
        <div className="docs_container">
            <p>
                ALL DOCUMENTS ARE REQUIRED BEFORE THE BROKER TO SUBMIT THE APPLICATION
                <span className="required">*</span>
            </p>
            <h2>Upload Documents</h2>
            <div className="upload-documents-page">
                {documentsList.map((doc, index) => (
                    <Docs key={index} title={doc.title} desc={doc.desc} id={index} />
                ))}
            </div>
        </div>
    );
}

export default UploadDocument;
