import React from "react";
import '../style/footer.css';

function Footer() {
    return (
        <footer className='footerStyle'>
            <p>Contact details...</p>
        </footer>
    );
}

export default Footer;
