import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


//input fiels component
export const InputField = ({ label, htmlFor, placeholder }) => (
    <div key={label} className="input-field-row">
        <div style={{ width: '40%' }}><label htmlFor={htmlFor}>{label}</label></div>
        <div style={{ width: "60%" }}><input className="details" type="text" id={htmlFor} placeholder={placeholder} /></div>
    </div>
);

//button component
export const ButtonComponent = ({ name }) => (
    <div className="btn-div">
        <button type="button" className="proceed-btn">
            {name}
        </button>
    </div>
)

//date of birth component i.e. calender component or a date picker
export const DateOfBirthPicker = () => {
    const [selectedDate, setSelectedDate] = useState(null);  

    return (
        <div className="input-field-row">
            <div style={{ width: '40%' }}> <label htmlFor="dob">Date of Birth:</label></div>
            <div style={{ width: "60%" }} className="details">
                <DatePicker
                    showIcon
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select your date of birth"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                />
            </div>
        </div>
    );
};

// Radio button component
export const Radiobutton = ({ label, htmlFor, options, name, value, onChange }) => {
    return (
        <div>
            <div className="input-field-row">
                <div style={{ width: '40%' }}>
                    <label htmlFor={htmlFor}>{label}</label>
                </div>

                <div className="option-div" style={{ width: '60%' }}>
                    {options.map((option, index) => (
                        <label key={index} style={{ margin: '5px 30px 5px 0px' }}>
                            <input
                                className="radio-btn"
                                type="radio"
                                value={option.value}
                                checked={value === option.value}
                                onChange={(e) => onChange(e.target.value)}
                                name={name}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Dropdown or Select box component
export const SelectBox = ({ label, htmlFor, options, name, onChange, defaultValue }) => {
    const SelectValueChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="input-field-row">
            <div style={{ width: '40%' }}>
                <label htmlFor={htmlFor}>{label}</label>
            </div>
            <div className="SelectBox-div" style={{ width: '60%' }}>
                <select className="select_box" name={name} onChange={SelectValueChange} defaultValue={defaultValue}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                </div>
        </div>
    );
};